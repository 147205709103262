import React, { useState, useEffect } from 'react'
import './Projects.scss';
import './components/ProjectItem';
import ProjectItem from './components/ProjectItem';
import AddProjectModal from './components/AddProjectModal';
import axios from 'axios';


const Projects = () => {
  const [projects, setProjects] = useState([]);
  const [projectModalOpen, setProjectModalOpen] = useState(false);
  const [token, setToken] = useState(null);
  const showProjectModal = () => {
    setProjectModalOpen(true);
  };
  const handleProjectModalCancel = () => {
    setProjectModalOpen(false);
  };

  useEffect(() => {
    setToken(window.localStorage.getItem('token'));
  }, [])

  async function getProjects() {
    try {
        const response = await axios.post('https://soultri.site/sochi_park_gitlab/api/projects/get_projects.php', null, {
            headers: {
                'Authorization': window.localStorage.getItem("token")
            }
        });

        console.log(response.data.projects)
        setProjects(response.data.projects);
    } catch (error) {
        console.error('Ошибка при получении списка проектов:', error);
    }
  }

  useEffect(() => {
    getProjects();
  }, [])

  return (
    <>
      <div className="projects__header">
        <h1 className="title projects__title">Проекты</h1>
        <button className="button--purple projects__button" onClick={showProjectModal}>Создать новый проект</button>
        <AddProjectModal open={projectModalOpen} onCancel={handleProjectModalCancel} />
      </div>
      <div className="projects__wrapper">
        {projects.map((project) => (
          <ProjectItem key={project.id} title={project.title} tickets={project.tickets} amountOfMembers={project.members.length} />
        ))}
      </div>
    </>
  )
}


export default Projects