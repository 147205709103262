import React, { useState, useEffect } from "react";
import EmployeesTable from "./components/EmployeesTable";
import "./Employees.scss";
import { Modal, Input } from "antd";
import axios from "axios";
import AddEmployeeModal from "./components/AddEmployeeModal";
import { Link } from "react-router-dom";

const Employees = () => {
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [token, setToken] = useState(null);

  const showAddModal = () => {
    setIsAddModalOpen(true);
  };
  const handleAddModalCancel = () => {
    setIsAddModalOpen(false);
  };

  useEffect(() => {
    setToken(window.localStorage.getItem('token'));
  }, [])

  const handleUpdateEmployees = () => {

    const headers = {
      "Content-Type": "application/json",
      Authorization: window.localStorage.getItem("token"), 
    };

    const url = `https://soultri.site/sochi_park_gitlab/api/employees/update_employees.php`;

    axios
      .post(url, null, { headers })
      .then((response) => {
        console.log("Сотрудники успешно обновлены:", response.data);
      })
      .catch((error) => {
        console.error("Произошла ошибка при обновлении сотрудников:", error);
      });
  }

  return (
    <div className="employees">
      <div className="employees__header">
        <h1 className="title employees">Сотрудники</h1>
        <div className="employees__left">
          <Link to={'/blacklist'} className="employees__left-blacklist">Черный список</Link>
          <button className="employees__left-button--white" onClick={handleUpdateEmployees}>Обновить</button>
          {/* <button
            className="button--purple employees__left-button--purple"
            onClick={showAddModal}
          >
            Добавить сотрудника
          </button> */}
          <AddEmployeeModal open={isAddModalOpen} cancel={handleAddModalCancel} />
        </div>
      </div>
      <EmployeesTable />
    </div>
  );
};

export default Employees;
