import React, { useState, useEffect } from "react";
import "./Auth.scss";
import { Input, notification } from "antd";
import axios from "axios";
import { useNavigate } from 'react-router-dom';

const Auth = () => {
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const nav = useNavigate();

  const handleLogin = () => {
    const headers = {
      "Content-Type": "application/json",
    };

    const url = `https://soultri.site/sochi_park_gitlab/api/login.php`;

    const requestData = {
      login: login,
      password: password,
    };

    if (login === "") {
      setError('login');
      return;
    }
    if (password === "") {
      setError('password');
      return;
    }
    setError("");

    axios
      .post(url, JSON.stringify(requestData), { headers })
      .then((response) => {
        console.log("Успешный вход:", response.data);
        // notification.success({message: 'Успешная авторизация'})
        window.localStorage.setItem("token", response.data.token);
        nav('/projects');
        window.location.reload();
      })
      .catch((error) => {
        console.error("Произошла ошибка при входе:", error);
        notification.error({message: 'Ошибка при авторизации'})
      });
  };

  return (
    <div className="auth">
      <div className="auth__box">
        <h1 className="auth__box-title">Авторизация</h1>
        <div className="df jcsb">
          <label className="auth__box-label">Логин</label>
          {error === 'login' ? <span className="auth__box-error">ошибка</span> : null}
        </div>
        <Input
          className={`auth__box-input ${error === 'login' ? 'auth__box-input--error' : null}`}
          style={{ marginBottom: "24px" }}
          value={login}
          onChange={(e) => setLogin(e.target.value)}
        />
        <div className="df jcsb">
          <label className="auth__box-label">Пароль</label>
          {error === 'password' ? <span className="auth__box-error">ошибка</span> : null} 
        </div>
        <Input
          className={`auth__box-input ${error === 'password' ? 'auth__box-input--error' : null}`}
          style={{ marginBottom: "24px" }}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button className={`auth__box-button ${login && password ? 'auth__box-button--colored' : null}`} onClick={handleLogin}>
          Войти
        </button>
      </div>
    </div>
  );
};

export default Auth;
