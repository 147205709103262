import React from "react";
import "./Sidebar.scss";
import { Link } from "react-router-dom";
import Sidebar1 from "../../assets/icons/Sidebar1";
import Sidebar2 from "../../assets/icons/Sidebar2";
import Sidebar3 from "../../assets/icons/Sidebar3";
import Sidebar4 from "../../assets/icons/Sidebar4";
import { CiLogout } from "react-icons/ci";

const Sidebar = () => {
  return (
    <aside className="sidebar">
      {/* <Link to='/' className="sidebar__link"><p className="sidebar__logo">Logo</p></Link> */}
      <div className="sidebar__wrapper">
        <nav className="sidebar-nav">
          <ul className="sidebar-nav__list">
            <li className="sidebar-nav__list-item">
              <Link to={"/projects"} className="sidebar-nav__list-link">
                <Sidebar1 stroke={"#2A2A2A"} />
                <p className="sidebar-nav__item-text">Проекты</p>
              </Link>
            </li>
            <li className="sidebar-nav__list-item">
              <Link to={"/tickets"} className="sidebar-nav__list-link">
                <Sidebar2 stroke={"#2A2A2A"} />
                <p className="sidebar-nav__item-text">Заявки в поддержку</p>
              </Link>
            </li>
            <li className="sidebar-nav__list-item">
              <Link to={"/"} className="sidebar-nav__list-link">
                <Sidebar3 stroke={"#2A2A2A"} />
                <p className="sidebar-nav__item-text">Открыть на Gitlab</p>
              </Link>
            </li>
            <li className="sidebar-nav__list-item">
              <Link to={"/employees"} className="sidebar-nav__list-link">
                <Sidebar4 stroke={"#2A2A2A"} />
                <p className="sidebar-nav__item-text">Сотрудники</p>
              </Link>
            </li>
            {window.localStorage.getItem("token") ? (
              <li className="sidebar-nav__list-item sidebar-nav__list-item--logout">
                <Link
                  to={"/auth"}
                  onClick={() => window.localStorage.removeItem("token")}
                  className="sidebar-nav__list-link"
                >
                  <CiLogout />
                  <p className="sidebar-nav__item-text">Выйти</p>
                </Link>
              </li>
            ) : null}
          </ul>
        </nav>
      </div>
    </aside>
  );
};

export default Sidebar;
