import React from "react";
import "../Conversation.scss";
import chainIcon from "../../../assets/icons/chain-icon.svg";
import { Link } from "react-router-dom";

const RelatedTickets = ({ relatedTickets }) => {
  console.log(relatedTickets)
  return (
    <>
      {relatedTickets.map((relatedTicket) => (
        // <Link to={"/conversation/" + relatedTicket.id} key={relatedTicket.id}>
        //   <p className="relatedTickets">
        //     <img className="relatedTickets__img" src={chainIcon} alt="" />
        //     <span className="relatedTickets__text">{relatedTicket.title}</span>
        //   </p>
        // </Link>
        <Link to={`/conversation/${relatedTicket.id}`} onClick={window.location.reload} key={relatedTicket.id}>
          <p className="relatedTickets">
            <img className="relatedTickets__img" src={chainIcon} alt="" />
            <span className="relatedTickets__text">{relatedTicket.title}</span>
          </p>
        </Link>
      ))}
    </>
  );
};

export default RelatedTickets;
