import React from "react";
import "../Conversation.scss";
import replyIcon from "../../../assets/icons/reply-icon.svg";
import dotsIcon from "../../../assets/icons/dots-icon.svg";
import sendIcon from "../../../assets/icons/send-icon.svg";

const ReplyItem = ({ messages }) => {

  if (messages !== 'none') {
    return (
      <>
        {messages.map((message) => (
          <div className="reply__message">
            <div className="reply__message-header">
              <div
                style={{ marginBottom: "7px", padding: "9px 15px 0px" }}
                className="df jcsb aic"
              >
                <div className="conv__message-left df">
                  <h5 className="conv__message-name">
                  </h5>
                  {/* <h5 className="conv__message-name">
                    Екатерина <span>@test1234567</span>
                  </h5> */}
                  <p className="conv__message-time">{message.date}</p>
                </div>
                <div className="conv__message-right">
                  <img
                    style={{ marginRight: "10px" }}
                    src={replyIcon}
                    alt="reply"
                  />
                  <img src={dotsIcon} alt="." />
                </div>
              </div>
              <p
                style={{ paddingBottom: "13px", paddingLeft: "15px" }}
                className="conv__message-content"
              >
                {message.message}
              </p>
            </div>
          </div>
        ))}
      </>
    );
  }
};

export default ReplyItem;
