import React from 'react'

const Sidebar1 = ({stroke}) => {
  return (
    <svg width="19.000000" height="19.000000" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
	<desc>
			Created with Pixso.
	</desc>
	<defs/>
	<path id="Icon" d="M0 9L8.67 13.36C8.79 13.42 8.85 13.45 8.91 13.46C8.97 13.47 9.02 13.47 9.08 13.46C9.14 13.45 9.2 13.42 9.32 13.36L18 9M0 13.52L8.67 17.89C8.79 17.95 8.85 17.98 8.91 17.99C8.97 18 9.02 18 9.08 17.99C9.14 17.98 9.2 17.95 9.32 17.89L18 13.52M0 4.47L8.67 8.83C8.79 8.89 8.85 8.92 8.91 8.93C8.97 8.94 9.02 8.94 9.08 8.93C9.14 8.92 9.2 8.89 9.32 8.83L18 4.47L9.32 0.1C9.2 0.04 9.14 0.01 9.08 0C9.02 -0.01 8.97 -0.01 8.91 0C8.85 0.01 8.79 0.04 8.67 0.1L0 4.47Z" stroke={stroke} stroke-opacity="1.000000" stroke-width="1.500000" stroke-linejoin="round"/>
</svg>
  )
}

// #4D4AEA

export default Sidebar1