import React, {useState} from "react";
import { Input, Modal } from "antd";

const AddEmployeeModal = ({open, cancel}) => {
  const [fio, setFio] = useState("");
  const [role, setRole] = useState("");
  const [department, setDepartment] = useState("");
  const [email, setEmail] = useState("");

  const handleAddEmployee = () => {
    //   const newEmployeeData = {
    //     name: fio,
    //     position: role,
    //     department_id: department,
    //     email: email
    //   };
  
    //   const headers = {
    //     "Content-Type": "application/json",
    //     Authorization: "YOUR_AUTH_TOKEN_HERE", // Замените на ваш токен авторизации
    //   };
  
    //   // URL для запроса
    //   const url = `https://soultri.site/sochi_park_gitlab/api/employees/update_employees.php`;
  
    //   // Отправка POST запроса с данными нового сотрудника
    //   axios
    //     .post(url, newEmployeeData, { headers })
    //     .then((response) => {
    //       console.log("Новый сотрудник успешно добавлен:", response.data);
    //       // Обработка успешного добавления сотрудника
    //     })
    //     .catch((error) => {
    //       console.error("Произошла ошибка при добавлении сотрудника:", error);
    //     });
    };

  return (
    <Modal
      open={open}
      onCancel={cancel}
      footer={[]}
      width={400}
    >
      <h5 className="addProj__title">Добавить сотрудника</h5>
      <p className="addEmployee__label">ФИО</p>
      <Input
        className="addProj__input"
        onChange={(e) => setFio(e.target.value)}
      />
      <p className="addEmployee__label">Должность</p>
      <Input
        className="addProj__input"
        onChange={(e) => setRole(e.target.value)}
      />
      <p className="addEmployee__label">Отдел</p>
      <Input
        className="addProj__input"
        onChange={(e) => setDepartment(e.target.value)}
      />
      <p className="addEmployee__label">Электронная почта</p>
      <Input
        className="addProj__input"
        onChange={(e) => setEmail(e.target.value)}
      />
      <div className="addProj__footer">
        <button
          className="addProj__footer-button addProj__footer-button--purple"
          onClick={handleAddEmployee}
        >
          Сохранить
        </button>
        <button
          className="addProj__footer-button addProj__footer-button--white"
          onClick={cancel}
        >
          Удалить
        </button>
      </div>
    </Modal>
  );
};

export default AddEmployeeModal;
