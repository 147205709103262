import React from 'react'

const Sidebar2 = ({stroke}) => {
  return (
    <svg width="19.000000" height="19.000000" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
	<desc>
			Created with Pixso.
	</desc>
	<defs/>
	<path id="Icon" d="M9.71 5.4C10.72 5.4 11.23 5.4 11.61 5.59C11.95 5.76 12.23 6.04 12.4 6.38C12.59 6.76 12.59 7.27 12.59 8.28L12.59 15.12C12.59 16.12 12.59 16.63 12.4 17.01C12.23 17.35 11.95 17.63 11.61 17.8C11.23 18 10.72 18 9.71 18L2.87 18C1.87 18 1.36 18 0.98 17.8C0.64 17.63 0.36 17.35 0.19 17.01C0 16.63 0 16.12 0 15.12L0 8.28C0 7.27 0 6.76 0.19 6.38C0.36 6.04 0.64 5.76 0.98 5.59C1.36 5.4 1.87 5.4 2.87 5.4L9.71 5.4ZM3.59 11.7L5.4 13.5L9.45 9.45M5.4 5.4L5.4 2.87C5.4 1.87 5.4 1.36 5.59 0.98C5.76 0.64 6.04 0.36 6.38 0.19C6.76 0 7.27 0 8.28 0L15.12 0C16.12 0 16.63 0 17.01 0.19C17.35 0.36 17.63 0.64 17.8 0.98C18 1.36 18 1.87 18 2.87L18 9.71C18 10.72 18 11.23 17.8 11.61C17.63 11.95 17.35 12.23 17.01 12.4C16.63 12.59 16.12 12.59 15.12 12.59L12.59 12.59" stroke={stroke} stroke-opacity="1.000000" stroke-width="1.500000" stroke-linejoin="round"/>
</svg>

  )
}

// #2A2A2A

export default Sidebar2