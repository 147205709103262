import React, { useEffect, useState } from 'react'
import { Table } from 'antd';
import axios from 'axios';
import chainIcon from '../../../assets/icons/chain-icon.svg';
import Sidebar4 from '../../../assets/icons/Sidebar4';
import calendarIcon from '../../../assets/icons/calendar-icon.svg';
import '../Tickets.scss';
import formatSeconds from '../../../utils/millisecondsToTime';
import MessagesIcon from '../../../assets/icons/MessagesIcon';
import { Link } from 'react-router-dom';

const RequestsTable = () => {
  const [tickets, setTickets] = useState([]);
  const [firstTickets, setFirstTickets] = useState([]);
  const [openTickets, setOpenTickets] = useState([]);
  const [closeTickets, setCloseTickets] = useState([]);
  const [transformedOpenTickets, setTransformedOpenTickets] = useState([]);
  const [transformedCloseTickets, setTransformedCloseTickets] = useState([]);
  const [dataforTable, setDataForTable] = useState(tickets);
  const [token, setToken] = useState(null);

  useEffect(() => {
    setToken(window.localStorage.getItem('token'));
  }, [])

  const getTickets = () => {
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': window.localStorage.getItem("token")
  };
  
    const url = 'https://soultri.site/sochi_park_gitlab/api/tickets/get_tickets.php';
    
    axios.post(url, JSON.stringify({order_by: 'id', order: 'DESC'}), { headers })
        .then(response => {
            const tickets = [];
            response.data.tickets.forEach(ticket => {
              tickets.push(transformObject(ticket))
            });
            let openTickets = response.data.tickets.filter(function(ticket) {
              return ticket.status_title === "Открыто";
            });
            setOpenTickets(openTickets);
            const transformedOpenTickets = [];
            openTickets.forEach(ticket => {
              transformedOpenTickets.push(transformObject(ticket))
            });
            setTransformedOpenTickets(transformedOpenTickets);
            let closeTickets = response.data.tickets.filter(function(ticket) {
              return ticket.status_title === "Закрыто";
            });
            setCloseTickets(closeTickets);
            const transformedCloseTickets = [];
            closeTickets.forEach(ticket => {
              transformedCloseTickets.push(transformObject(ticket))
            });
            setTransformedCloseTickets(transformedCloseTickets);
            setTickets(tickets);
            setFirstTickets(response.data.tickets);
        })
        .catch(error => {
            console.error('Произошла ошибка:', error);
        });
  }
  

  useEffect(() => {
    getTickets();
  }, [])


  function transformObject(inputObject) {
    return {
      id: inputObject.id,
      request: [inputObject.title, inputObject.id, inputObject.status_title],
      project: inputObject.project_title,
      department: inputObject.department_title,
      employees: inputObject.member_count,
      urgency: [inputObject.urgency_title, inputObject.urgency_text_color, inputObject.urgency_back_color],
      status: inputObject.status_title,
      "res-time": inputObject.avg_answer_time,
      date: [inputObject.created_date, inputObject.last_message_time],
      dialog: [inputObject.messages_count, inputObject.status_title]
    };
  }  

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      render: (text) => (
        <p>#{text}</p>
      )
    },
    {
      title: 'Заявка',
      dataIndex: 'request',
      key: 'request',
    render: (_, { request }) => (
      <Link to={'/conversation/'+request[1]}>
              <p className='requests__table-req df'>{request[0]} <img src={chainIcon} alt="." /></p>
      </Link>
    ), 
    },
    {
      title: 'Проект',
      dataIndex: 'project',
      key: 'project',
    },
    {
      title: 'Отдел',
      key: 'department',
      dataIndex: 'department',
    },
    {
      title: 'Сотрудники',
      key: 'employees',
      dataIndex: 'employees',
      render: (text) => (
        <div className="requests__table-members df aic">
          <Sidebar4 stroke={'#4D4AEA'} />
          <span>{text}</span>
        </div>
      )
    },
    {
      title: 'Срочность',
      key: 'urgency',
      dataIndex: 'urgency',
      render: (_, { urgency }) => (
        <p style={{color: urgency[1], backgroundColor: urgency[2]}} className="requests__table-urgency">{urgency[0]}</p>
      ),
    },
    {
      title: 'Статус',
      key: 'status',
      dataIndex: 'status',
      render: (text) => {
        if (text === 'Открыто') {
          return (<p style={{color: 'rgb(77, 74, 234)'}}>{text}</p>)
        } else {
          return (<p>{text}</p>)
        }
      }
    },
    {
      title: 'Ср. время ответа',
      key: 'res-time',
      dataIndex: 'res-time',
      render: (text) => (
        <p>{formatSeconds(text)}</p>
      )
    },
    {
      title: 'Дата начала и завершения',
      key: 'date',
      dataIndex: 'date',
      render: (_, { date }) => (
        <>
          <p className="requests__table-date">
            <img src={calendarIcon} alt="." /> <span>{date[0] ? date[0] : 'нет данных'}</span>
          </p>
          <p className="requests__table-date">
            <img src={calendarIcon} alt="." /> <span>{date[1] ? date[1] : 'ещё открыто'}</span>
          </p>
        </>
      ), 
    },
    {
      title: '',
      key: 'dialog',
      dataIndex: 'dialog',
      render: (_, { dialog }) => {
        let dialogColorText = '#959595';
        if (dialog[1] === 'Открыто') {
          dialogColorText = 'rgb(77, 74, 234)';
        }
        return (
          <div className="requests__table-dialog df aic">
            <MessagesIcon stroke={dialogColorText} />
            <span style={{color: dialogColorText}}>{dialog[0]}</span>
          </div>
        )
      }
    },
  ];




  return (
    <>
    <div className="requests__header">
    <h1 className="title requests__title">Заявки в поддержку</h1>
    <div className="requests__top">
      <div className="requests__left">
        <div className='requests__left-tab' onClick={() => setDataForTable(transformedOpenTickets)}><p className="requests__tab-text">Открытые</p> <span className="requests__tab-badge">{openTickets.length}</span></div>
        <div className='requests__left-tab' onClick={() => setDataForTable(transformedCloseTickets)}><p className="requests__tab-text">Закрытые</p> <span className="requests__tab-badge">{tickets.length-openTickets.length}</span></div>
        <div className='requests__left-tab' onClick={() => setDataForTable(tickets)}><p className="requests__tab-text">Все</p> <span className="requests__tab-badge">{tickets.length}</span></div>
      </div>
      <div className="requests__right">
        <Link to='/tickets-new'>
          <button className="button--purple reuqests__right-button">Оставить заявку</button>
        </Link>
      </div>
    </div>
    </div>
    <div className="requests__table">
      <Table columns={columns} dataSource={dataforTable.length==0 ? tickets : dataforTable} /> 
    </div>
    </>
  )
}

export default RequestsTable