import React from "react";
import backArrow from "../../assets/icons/left-arrow.svg";
import BlacklistTable from "./components/BlacklistTable";

const Blacklist = () => {
  return (
    <>
      <div className="conv__header">
        <div className="conv__header-left">
          <img
            className="conv__header-back"
            src={backArrow}
            alt="back"
            onClick={() => window.history.back()}
          />
          <h1 className="title conv__header-title">Черный список</h1>
        </div>
      </div>
      <BlacklistTable />
    </>
  );
};

export default Blacklist;
