import React, { useState } from "react";
import "../Projects.scss";
import project1 from "../../../assets/images/project-1.png";
import Sidebar2 from "../../../assets/icons/Sidebar2";
import Sidebar4 from "../../../assets/icons/Sidebar4";
import AddEmployeeModal from "../../employees/components/AddEmployeeModal";
import { Link } from "react-router-dom";

const ProjectItem = ({ title, tickets, amountOfMembers }) => {
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);

  const showAddModal = () => {
    setIsAddModalOpen(true);
  };
  const handleAddModalCancel = () => {
    setIsAddModalOpen(false);
  };

  return (
    <Link to={"/tickets"}>
    <div className="project__item">
      <div className="project__left">
        <img src={project1} alt="" />
        <div className="project__descr">
          <h4 className="project__descr-title">{title}</h4>
          <div className="df aic">
            <div className="project__descr-members">
              <Sidebar4 stroke={"#4D4AEA"} />
              <span className="project__descr-amount">{amountOfMembers}</span>
            </div>
            {/* <button className="project__descr-button" onClick={showAddModal}>
              Добавить сотрудников
            </button> */}
            <AddEmployeeModal
              open={isAddModalOpen}
              cancel={handleAddModalCancel}
            />
          </div>
        </div>
      </div>
      <div className="project__right">
          <button className="project__button">
            <Sidebar2 stroke={"#4D4AEA"} />
            <p className="project__button-text">{tickets}</p>
          </button>
      </div>
    </div>
    </Link>
  );
};

export default ProjectItem;
