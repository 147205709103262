import React, { useEffect, useState } from "react";
import replyIcon from "../../../assets/icons/reply-icon.svg";
import dotsIcon from "../../../assets/icons/dots-icon.svg";
import { Collapse } from "antd";
import ReplyItem from "./ReplyItem";
import sendIcon from "../../../assets/icons/send-icon.svg";
import axios from "axios";

const MessageItem = ({
  message,
  employees,
  id,
  getTicketInfo,
  replies,
  isOpenTicket,
  // generalExecutor,
  children,
}) => {
  const [replyMessage, setReplyMesssage] = useState("");
  const [token, setToken] = useState(null);

  useEffect(() => {
    setToken(window.localStorage.getItem('token'));
  }, [])


  const reply = [
    {
      key: "1",
      label: "Ответы",
      children: <ReplyItem messages={replies.length > 0 ? replies : "none"} />,
    },
  ];


  const handleReplyMessage = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: window.localStorage.getItem("token"),
    };

    const url = `https://soultri.site/sochi_park_gitlab/api/tickets/reply_to_message.php`;

    const requestData = {
      ticket_id: id,
      message_id: message.id,
      message: replyMessage,
    };

    axios
      .post(url, requestData, { headers })
      .then((response) => {
        console.log("Ответ успешно отправлен:", response.data);
        setReplyMesssage("");
        getTicketInfo();
      })
      .catch((error) => {
        console.error("Произошла ошибка при отправке ответа:", error);
      });
  };

  return (
    <div className="conv__message">
      <div className="conv__message-header">
        <div
          style={{ marginBottom: "7px", padding: "9px 15px 0px" }}
          className="df jcsb aic"
        >
          <div className="conv__message-left df">
            {/* <h5 className="conv__message-name">
              Екатерина <span>@test1234567</span>
            </h5> */}
            {employees
              ? employees.map((employee) => {
                  if (employee.id === message.employee_id) {
                    // generalExecutor([employee.name, employee.role])
                    return (
                      <h5 className="conv__message-name">
                        {employee.name} <span></span>
                      </h5>
                    );
                  }
                })
              : null}

            <p className="conv__message-time">{message.date}</p>
          </div>
          <div className="conv__message-right">
            <img style={{ marginRight: "10px" }} src={replyIcon} alt="reply" />
            <img src={dotsIcon} alt="." />
          </div>
        </div>
        <p
          style={{ paddingBottom: "13px", paddingLeft: "15px" }}
          className="conv__message-content"
        >
          {message.action_type === "1" ? message.message : null}
        </p>
        {replies.length > 0 ? (
          <Collapse
            className="conv__message-replies"
            items={reply}
            defaultActiveKey={["1"]}
          />
        ) : null}
        {isOpenTicket === "1" ? (
          <div className="reply__message-footer">
            <textarea
              rows="1"
              className="reply__message-textarea"
              placeholder="Ответ"
              value={replyMessage}
              onChange={(e) => setReplyMesssage(e.target.value)}
            ></textarea>
            <img
              className="reply__message-icon"
              src={sendIcon}
              alt="send"
              onClick={handleReplyMessage}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default MessageItem;
