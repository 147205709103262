import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Projects from './pages/projects/Projects';
import Tickets from './pages/tickets/Tickets';
import Sidebar from './components/sidebar/Sidebar';
import TicketsNew from './pages/tickets/TicketsNew';
import Conversation from './pages/conversation/Conversation';
import Employees from './pages/employees/Employees';
import Blacklist from './pages/employees/Blacklist';
import Auth from './pages/auth/Auth';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';



function App() {
  

  


  return (
    <>
      <Router>
        <Layout />
      </Router>
    </>
  );
}


const Layout = () => {
  const [isAuthPage, setIsAuthPage] = useState(false);
  const [token, setToken] = useState(null);
  const nav = useNavigate();

  useEffect(() => {
    if (window.location.pathname === '/auth') {
      setIsAuthPage(true);
    } else {
      setIsAuthPage(false);
    }
    setToken(window.localStorage.getItem('token'));
    if (!window.localStorage.getItem('token')) {
      nav('/auth');
    }
  }, [])

  return (
    <div className={`${isAuthPage ? null : 'wrapper'}`}>
          <div className={`${isAuthPage ? 'none' : null}`}>
            <Sidebar />
          </div>
          <div className={`${isAuthPage ? null : 'content'}`}>
            <Routes>
                    <Route path="/auth" element={<Auth />}/>
                    <Route path="/" element={<Projects />}/>
                    <Route path="/projects" element={<Projects />}/>
                    <Route path="/tickets" element={<Tickets />}/>
                    <Route path="/tickets-new" element={<TicketsNew />}/>
                    <Route path="/conversation/:ticketId" element={<Conversation />}/>
                    <Route path="/employees" element={<Employees />}/>
                    <Route path="/blacklist" element={<Blacklist />}/>
            </Routes>
          </div>
        </div>
  )
}


export default App;
