import React, { useEffect, useState } from "react";
import "./Conversation.scss";
import backArrow from "../../assets/icons/left-arrow.svg";
import trashIcon from "../../assets/icons/trash-icon.svg";
import chainIcon from "../../assets/icons/chain-icon.svg";
import replyIcon from "../../assets/icons/reply-icon.svg";
import dotsIcon from "../../assets/icons/dots-icon.svg";
import sendIcon from "../../assets/icons/send-icon.svg";
import pencilIcon from "../../assets/icons/pencil-icon.svg";
import { Collapse, DatePicker, Select, Modal } from "antd";
import axios from "axios";
import "./components/ReplyItem";
import ReplyItem from "./components/ReplyItem";
import { useParams } from "react-router-dom";
import MessageItem from "./components/MessageItem";
import RelatedTickets from "./components/RelatedTickets";
import ChangeUrgency from "./components/ChangeUrgency";

const Conversation = () => {
  const [ticketInfo, setTicketInfo] = useState({});
  const [relatedTickets, setRelatedTickets] = useState([]);
  const [ticketMessages, setTicketMessages] = useState([]);
  const [urgency, setUrgency] = useState(null);
  const [date, setDate] = useState("");
  const [ticketMessage, setTicketMessage] = useState("");
  const [employees, setEmployees] = useState([]);
  let { ticketId } = useParams();
  const [isRelatedModalOpen, setIsRelatedModalOpen] = useState(false);
  const [selectTicket, setSelectTicket] = useState(null);
  const [allTickets, setAllTickets] = useState([]);
  const [assigneeId, setAssigneeId] = useState(null);
  const [generalExecutor, setGeneralExecutor] = useState(null);
  const [isChangeAssigneeModalOpen, setIsChangeAssigneeModalOpen] =
    useState(false);
  const [empOptions, setEmpOptions] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState([]);
  const [token, setToken] = useState(null);

  useEffect(() => {
    setToken(window.localStorage.getItem('token'));
  }, [])

  const showEditAssigneeModal = () => {
    setIsChangeAssigneeModalOpen(true);
  };
  const handleEditAssigneeModalCancel = () => {
    setIsChangeAssigneeModalOpen(false);
  };

  const handleEmployeeChange = (value) => {
    setSelectedEmployee(value);
  };

  const handleTicketSelect = (value) => {
    setSelectTicket(value);
  };
  const showRelatedModal = () => {
    setIsRelatedModalOpen(true);
  };
  const handleRelatedModalCancel = () => {
    setIsRelatedModalOpen(false);
  };

  const url = window.location.href;

  const queryString = url.split("?")[1];
  const params = new URLSearchParams(queryString);
  const isOpenValue = decodeURIComponent(params.get("isOpen"));

  console.log(isOpenValue);

  const handleUrgencySelect = (value) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: window.localStorage.getItem("token"),
    };

    const url =
      "https://soultri.site/sochi_park_gitlab/api/tickets/change_urgency.php";

    const requestData = {
      ticket_id: ticketId,
      urgency_id: value,
    };

    axios
      .post(url, JSON.stringify(requestData), { headers })
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => {
        console.error("Произошла ошибка:", error);
      });
  };
  const handleDatePick = (date, dateString) => {
    setDate(dateString);
  };

  const reqs = [
    {
      key: "1",
      label: "Связанные заявки",
      children: <RelatedTickets relatedTickets={relatedTickets} />,
    },
  ];

  const getTickets = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: window.localStorage.getItem("token"),
    };

    const url =
      "https://soultri.site/sochi_park_gitlab/api/tickets/get_tickets.php";

    axios
      .post(url, JSON.stringify({ order_by: "id", order: "DESC" }), { headers })
      .then((response) => {
        let transformedArray = response.data.tickets.map(function (ticket) {
          return {
            value: ticket.id,
            label: ticket.title,
          };
        });
        setAllTickets(transformedArray);
      })
      .catch((error) => {
        console.error("Произошла ошибка:", error);
      });
  };

  useEffect(() => {
    getTickets();
  }, []);

  const getTicketInfo = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: window.localStorage.getItem("token"),
    };

    const url = `https://soultri.site/sochi_park_gitlab/api/tickets/get_ticket.php`;

    axios
      .post(url, { ticket_id: ticketId }, { headers })
      .then((response) => {
        console.log("Ответ от сервера:", response.data);
        setTicketInfo(response.data);
        function sortActivitiesByDate(a, b) {
          return new Date(a.date) - new Date(b.date);
        }
        console.log(response.data.activities.sort(sortActivitiesByDate))
        setTicketMessages(response.data.activities.sort(sortActivitiesByDate));
        setRelatedTickets(response.data.links);
        setAssigneeId(response.data.assignee_id);
        switch (response.data.urgency_id) {
          case "1":
            setUrgency("Низкая срочность");
            break;
          case "2":
            setUrgency("Средняя срочность");
            break;
          case "3":
            setUrgency("Высокая срочность");
            break;
        }
      })
      .catch((error) => {
        console.error("Произошла ошибка:", error);
      });
  };

  useEffect(() => {
    getTicketInfo();
    const intervalId = setInterval(() => {
      getTicketInfo();
    }, 10000);
    return () => clearInterval(intervalId);
  }, []);

  const handleLinkTicket = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: window.localStorage.getItem("token"),
    };

    const requestData = {
      ticket_host_id: ticketInfo.id,
      ticket_linked_id: selectTicket,
    };

    const url = `https://soultri.site/sochi_park_gitlab/api/tickets/link_ticket.php`;

    axios
      .post(url, JSON.stringify(requestData), { headers })
      .then((response) => {
        console.log("Ответ от сервера:", response.data);
        handleRelatedModalCancel();
        window.location.reload();
      })
      .catch((error) => {
        console.error("Произошла ошибка:", error);
      });
  };

  const handleSendMessage = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: window.localStorage.getItem("token"),
    };

    const url = `https://soultri.site/sochi_park_gitlab/api/tickets/send_message.php`;

    const requestData = {
      ticket_id: ticketId.toString(),
      message: ticketMessage,
    };

    axios
      .post(url, JSON.stringify(requestData), { headers })
      .then((response) => {
        console.log("Сообщение успешно отправлено:", response.data);
        setTicketMessage("");
        getTicketInfo();
      })
      .catch((error) => {
        console.error("Произошла ошибка при отправке сообщения:", error);
      });
  };

  const getEmployees = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: window.localStorage.getItem("token"),
    };

    const url = `https://soultri.site/sochi_park_gitlab/api/employees/get_employees.php`;

    axios
      .post(url, {}, { headers })
      .then((response) => {
        console.log("Информация о сотрудниках:", response.data.employees);
        setEmployees(response.data.employees);
        setGeneralExecutor(
          response.data.employees.find((employee) => employee.id === assigneeId)
        );
        let transformedArray = response.data.employees.map(function (employee) {
          return {
            value: employee.id,
            label: employee.name,
          };
        });
        setEmpOptions(transformedArray);
      })
      .catch((error) => {
        console.error(
          "Произошла ошибка при получении информации о сотрудниках:",
          error
        );
      });
  };

  useEffect(() => {
    getEmployees();
  }, [ticketInfo]);

  const handleCloseTicket = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: window.localStorage.getItem("token"),
    };

    const url = `https://soultri.site/sochi_park_gitlab/api/tickets/close_ticket.php`;

    axios
      .post(url, { ticket_id: ticketId }, { headers })
      .then((response) => {
        console.log("Заявка успешно закрыта:", response.data);
      })
      .catch((error) => {
        console.error("Произошла ошибка при закрытии заявки:", error);
      });
  };

  const handleChangeAssignee = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: window.localStorage.getItem("token"),
    };

    const url = `https://soultri.site/sochi_park_gitlab/api/tickets/change_assignee.php`;

    const requestData = {
      ticket_id: ticketId.toString(),
      employee_id: selectedEmployee,
    };

    axios
      .post(url, JSON.stringify(requestData), { headers })
      .then((response) => {
        console.log("Главный исполнитель успешно изменён:", response.data);
        handleEditAssigneeModalCancel();
      })
      .catch((error) => {
        console.error("Произошла ошибка при изменении главного исполнителя:", error);
      });
  }

  const ShowItemFromAction = ({message}) => {
    if(message.action_type === '1' && message.replied_message === '0') {
      return <MessageItem
      key={message.id}
      id={ticketId}
      message={message}
      employees={employees}
      replies={ticketMessages.filter(
        (msg) => msg.replied_message === message.id
      )}
      // generalExecutor={setGeneralExecutor}
      getTicketInfo={getTicketInfo}
      isOpenTicket={ticketInfo.status_id}
    />
    }
    switch (message.action_type) {
      case '2':
        return <ChangeUrgency message={message} />;
      // case '3':
      //   return <div>Начало выполнения: {message.message}</div>;
      // case '4':
      //   return <div>Конец выполнения: {message.message}</div>;
      // case '5':
      //   return <div>Смена привязанного сотрудника: {message.message}</div>;
      default:
        return null;
    }
  };

  return (
    <div className="wrapper">
      <main className="conv__main">
        <div className="conv__header">
          <div className="conv__header-left">
            <img
              className="conv__header-back"
              src={backArrow}
              alt="back"
              onClick={() => window.history.back()}
            />
            <h1 className="title conv__header-title">Обсуждение</h1>
          </div>
          <div className="conv__header-right">
            <button
              className="conv__header-button--white"
              onClick={showRelatedModal}
            >
              <img src={chainIcon} alt="." /> <p>Создать связанную заявку</p>
            </button>
            <Modal
              open={isRelatedModalOpen}
              title=""
              onCancel={handleRelatedModalCancel}
              footer={[]}
              width={620}
              className="addProj__modal"
            >
              <h4 className="conv__related-title">Связанная заявка</h4>
              <Select
                className="conv__related-select"
                showSearch
                placeholder="Не выбрано"
                optionFilterProp="children"
                onChange={handleTicketSelect}
                options={allTickets}
              />
              <button
                className="conv__header-button--white conv__related-button"
                onClick={handleLinkTicket}
              >
                <img src={chainIcon} alt="." /> <p>Добавить заявку</p>
              </button>
            </Modal>
            {ticketInfo.status_id === "1" ? (
              <>
                <button
                  className="button--purple conv__header-button--purple"
                  onClick={handleCloseTicket}
                >
                  Закрыть заявку
                </button>
                <img
                  className="conv__header-delete"
                  src={trashIcon}
                  alt="delete"
                />
              </>
            ) : null}
          </div>
        </div>
        <div className="conv__project">
          <h4 className="conv__project-title">{ticketInfo.title}</h4>
          <p className="conv__project-descr">{ticketInfo.description}</p>
        </div>
        <Collapse className="conv__req" items={reqs} defaultActiveKey={["1"]} />
        {/* <h5 className="conv__date">Сегодня</h5> */}
        {/* {ticketMessages.map((message) =>
          message.replied_message === "0" && message.action_type === "1" ? (
            <MessageItem
              key={message.id}
              id={ticketId}
              message={message}
              employees={employees}
              replies={ticketMessages.filter(
                (msg) => msg.replied_message === message.id
              )}
              // generalExecutor={setGeneralExecutor}
              getTicketInfo={getTicketInfo}
              isOpenTicket={ticketInfo.status_id}
            />
          ) : null
        )} */}
         {ticketMessages.map((message, index) => (
        <ShowItemFromAction key={index} message={message} />
      ))}
        {/* {
          ticketMessages.map(message => (
            message.action_type === "2" ? (
              <p>{message.message}</p>
            ) : null
          ))
        } */}
        {ticketInfo.status_id === "1" ? (
          <div className="conv__footer">
            <div className="conv__footer-b">
              <textarea
                rows="2"
                className="conv__footer-textarea"
                placeholder="Текст"
                value={ticketMessage}
                onChange={(e) => setTicketMessage(e.target.value)}
              ></textarea>
              <img
                className="conv__footer-send"
                src={sendIcon}
                alt="send"
                onClick={handleSendMessage}
              />
            </div>
            <div className="conv__footer-button" onClick={handleCloseTicket}>
              Завершить выполнение
            </div>
          </div>
        ) : null}
      </main>
      <aside className="conv__aside">
        <h2 className="conv__aside-title">Настройки</h2>
        <span className="conv__aside-label">Срочность</span>
        <Select
          className="conv__aside-select"
          showSearch
          placeholder={urgency}
          optionFilterProp="children"
          onChange={handleUrgencySelect}
          value={urgency}
          options={[
            {
              value: "1",
              label: "Низкая срочность",
            },
            {
              value: "2",
              label: "Средняя срочность",
            },
            {
              value: "3",
              label: "Высокая срочность",
            },
          ]}
        />
        {/* <span className="conv__aside-label">Дата закрытия</span>
        <DatePicker className="conv__aside-date" onChange={handleDatePick} /> */}
        <span className="conv__aside-label">Главный исполнитель</span>
        <div className="conv__aside-executor">
          <h6 className="conv__executor-name">
            {generalExecutor ? generalExecutor.name : null}
          </h6>
          <p className="conv__executor-job">
            {generalExecutor ? generalExecutor.role : null}
          </p>
          <img src={pencilIcon} alt="" className="conv__executor-img" onClick={showEditAssigneeModal} />
          <Modal
            open={isChangeAssigneeModalOpen}
            title=""
            onCancel={handleEditAssigneeModalCancel}
            footer={[]}
            width={420}
            className="addProj__modal"
          >
            <h4 className="addEmp__title">Выбрать сотрудника</h4>
            <Select
              allowClear
              style={{ width: "100%" }}
              placeholder="Не выбрано"
              onChange={handleEmployeeChange}
              options={empOptions}
            />
            <div className="df jcc">
              <button
                className="button--purple addEmp__button"
                onClick={handleChangeAssignee}
              >
                Выбрать сотрудника
              </button>
            </div>
          </Modal>
        </div>
      </aside>
    </div>
  );
};

export default Conversation;
