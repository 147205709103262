import React, { useEffect, useState } from "react";
import { Table } from "antd";
import axios from "axios";
import chainIcon from "../../../assets/icons/chain-icon.svg";
import Sidebar4 from "../../../assets/icons/Sidebar4";
import calendarIcon from "../../../assets/icons/calendar-icon.svg";
import "../Employees.scss";
import formatMilliseconds from "../../../utils/millisecondsToTime";
import MessagesIcon from "../../../assets/icons/MessagesIcon";
import { Link } from "react-router-dom";
import closeIcon from "../../../assets/icons/close-icon.svg";

const EmployeesTable = () => {
  const [employees, setEmployees] = useState([]);
  const [token, setToken] = useState(null);

  useEffect(() => {
    setToken(window.localStorage.getItem('token'));
  }, [])



  const handleSetBlacklistEmployee = (id) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: window.localStorage.getItem("token"),
    };

    const url = `https://soultri.site/sochi_park_gitlab/api/employees/set_blacklist_status.php`;

    const requestData = {
      employee_id: id,
      blacklist_id: 1
    }

    axios
      .post(url, JSON.stringify(requestData), { headers })
      .then((response) => {
        console.log("Сотрудник занесён в чёрный список:", response.data.employees);
        getEmployees();
      })
      .catch((error) => {
        console.error(
          "Произошла ошибка при занесении сотрудника в чёрный список:",
          error
        );
      });
  }

  const getEmployees = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: window.localStorage.getItem("token"),
    };

    const url = `https://soultri.site/sochi_park_gitlab/api/employees/get_employees.php`;

    axios
      .post(url, {}, { headers })
      .then((response) => {
        console.log("Информация о сотрудниках:", response.data.employees);
        setEmployees(response.data.employees);
      })
      .catch((error) => {
        console.error(
          "Произошла ошибка при получении информации о сотрудниках:",
          error
        );
      });
  };

  useEffect(() => {
    getEmployees();
  }, []);

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      render: (text) => <p>#{text}</p>,
    },
    {
      title: "Сотрудник",
      dataIndex: "name",
      key: "name",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Должность",
      dataIndex: "role",
      key: "role",
    },
    {
      title: "Отдел",
      key: "department",
      dataIndex: "department",
    },
    {
      title: "Элеткронная почта",
      key: "email",
      dataIndex: "email",
    },
    {
      title: "GitLab ID",
      key: "gitlab_id",
      dataIndex: "gitlab_id",
    },
    // {
    //   title: 'Дата найма',
    //   key: 'status',
    //   dataIndex: 'status',
    // },
    {
      title: "Действие",
      key: "id",
      dataIndex: "id",
      render: (text) => (
        <img style={{ cursor: "pointer" }} src={closeIcon} alt="delete" onClick={() => handleSetBlacklistEmployee(text)} />
      ),
    },
  ];

  const data = employees;

  return (
    <div className="requests__table">
      <Table columns={columns} dataSource={data} />
    </div>
  );
};

export default EmployeesTable;
