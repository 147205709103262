import React, { useEffect, useState } from 'react';
import formatTimeAgo from '../../../utils/formatTimeAgo';
import axios from 'axios';

const ChangeUrgency = ({message}) => {
  // const [employees, setEmployees] = useState([]);
  // console.log(message)

  // const getEmployees = () => {
  //   const headers = {
  //     "Content-Type": "application/json",
  //     Authorization: window.localStorage.getItem("token"),
  //   };

  //   const url = `https://soultri.site/sochi_park_gitlab/api/employees/get_employees.php`;

  //   axios
  //     .post(url, {}, { headers })
  //     .then((response) => {
  //       console.log("Информация о сотрудниках:", response.data.employees);
  //       setEmployees(response.data.employees);
  //     })
  //     .catch((error) => {
  //       console.error(
  //         "Произошла ошибка при получении информации о сотрудниках:",
  //         error
  //       );
  //     });
  // };

  // useEffect(() => {
  //   getEmployees();
  // }, []);



  return (
    // <span className='conv__urgency-name'>{employees ? employees[message.employee_id].name : null}</span>
    <p className="conv__urgency">
       Добавлен статус <div className="conv__urgency-status" style={{color: message.urgency_text_color, backgroundColor: message.urgency_back_color}}>{message.urgency_title}</div>  <span className='conv__urgency-time'>{formatTimeAgo(message.date)}</span>
    </p>
  )
}

export default ChangeUrgency