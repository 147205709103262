import React from 'react'

const MessagesIcon = ({stroke}) => {
  return (
    <svg width="24.000000" height="24.000000" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
	<desc>
			Created with Pixso.
	</desc>
	<defs>
		<clipPath id="clip132_105">
			<rect id="message-chat-circle" width="24.000000" height="24.000000" transform="translate(-0.915039 0.000000)" fill="white" fill-opacity="0"/>
		</clipPath>
	</defs>
	<rect id="message-chat-circle" width="24.000000" height="24.000000" transform="translate(-0.915039 0.000000)" fill="#FFFFFF" fill-opacity="0"/>
	<g clip-path="url(#clip132_105)">
		<path id="Icon" d="M11.77 17.31C12.13 17.37 12.5 17.4 12.87 17.4C13.77 17.4 14.62 17.23 15.41 16.93C15.58 16.87 15.67 16.84 15.73 16.82C15.8 16.81 15.85 16.81 15.91 16.81C15.98 16.8 16.06 16.82 16.2 16.84L19.06 17.26C19.37 17.3 19.53 17.33 19.65 17.28C19.75 17.23 19.83 17.15 19.88 17.05C19.93 16.94 19.91 16.78 19.87 16.46L19.51 13.51C19.49 13.37 19.48 13.3 19.49 13.23C19.49 13.17 19.49 13.12 19.51 13.06C19.52 12.99 19.55 12.91 19.61 12.75C19.91 11.95 20.08 11.09 20.08 10.2C20.08 6.22 16.85 3 12.87 3C8.89 3 5.66 6.22 5.66 10.2C5.66 10.57 5.69 10.94 5.75 11.3M11.93 16.04C11.93 13.31 9.78 11.09 7.12 11.09C4.47 11.09 2.32 13.31 2.32 16.04C2.32 16.59 2.4 17.12 2.56 17.62C2.63 17.83 2.66 17.93 2.68 18C2.69 18.08 2.69 18.12 2.69 18.19C2.68 18.27 2.66 18.35 2.63 18.51L2.08 21L4.76 20.63C4.91 20.61 4.98 20.6 5.04 20.6C5.11 20.6 5.15 20.6 5.21 20.61C5.28 20.63 5.37 20.66 5.56 20.73C6.05 20.9 6.57 21 7.12 21C9.78 21 11.93 18.78 11.93 16.04Z" stroke={stroke} stroke-opacity="1.000000" stroke-width="1.500000" stroke-linejoin="round" stroke-linecap="round"/>
	</g>
</svg>

  )
}

export default MessagesIcon