import React from 'react'

const Sidebar4 = ({stroke}) => {
  return (
    <svg width="20.000000" height="15.000000" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
	<desc>
			Created with Pixso.
	</desc>
	<defs/>
	<path id="Vector" d="M6.83 5.83C5.17 5.83 3.82 4.48 3.82 2.81C3.82 1.15 5.17 -0.21 6.83 -0.21C8.5 -0.21 9.86 1.15 9.86 2.81C9.86 4.48 8.5 5.83 6.83 5.83Z" stroke={stroke} stroke-opacity="1.000000" stroke-width="1.500000" stroke-linejoin="round"/>
	<path id="Vector" d="M0.75 14.2L0.75 12.48C0.75 10.48 2.37 8.86 4.37 8.86L9.79 8.86C11.79 8.86 13.41 10.48 13.41 12.48L13.41 14.2" stroke={stroke} stroke-opacity="1.000000" stroke-width="1.500000" stroke-linejoin="round" stroke-linecap="round"/>
	<path id="Vector" d="M14.37 6.7C13.14 6.7 12.13 5.69 12.13 4.45C12.13 3.22 13.14 2.21 14.37 2.21C15.61 2.21 16.62 3.22 16.62 4.45C16.62 5.69 15.61 6.7 14.37 6.7Z" stroke={stroke} stroke-opacity="1.000000" stroke-width="1.500000" stroke-linejoin="round"/>
	<path id="Vector" d="M15.01 8.94L16.55 8.94C18.03 8.94 19.23 10.14 19.23 11.62L19.23 12.9" stroke={stroke} stroke-opacity="1.000000" stroke-width="1.500000" stroke-linejoin="round" stroke-linecap="round"/>
</svg>

  )
}

export default Sidebar4