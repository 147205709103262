import React, { useState, useEffect } from "react";
import { Input } from "antd";
import { Select, DatePicker } from "antd";
import "./Tickets.scss";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const TicketsNew = () => {
  const nav = useNavigate();
  const [name, setName] = useState("");
  const [project, setProject] = useState("");
  const [urgency, setUrgency] = useState("");
  const [department, setDepartment] = useState("");
  const [date, setDate] = useState("");
  const [descr, setDescr] = useState("");
  const [projects, setProjects] = useState([]);
  const [token, setToken] = useState(null);

  const handleProjectSelect = (value) => {
    setProject(value);
  };
  const handleUrgencySelect = (value) => {
    setUrgency(value);
  };
  const handleDepartmentSelect = (value) => {
    setDepartment(value);
  };
  const handleDatePick = (date, dateString) => {
    setDate(dateString);
  };
  useEffect(() => {
    setToken(window.localStorage.getItem("token"));
  }, []);

  async function getProjects() {
    console.log(token)
    if (token !== '') {
      try {
        const response = await axios.post(
          "https://soultri.site/sochi_park_gitlab/api/projects/get_projects.php",
          null,
          {
            headers: {
              Authorization: window.localStorage.getItem("token"),
            },
          }
        );

        let transformedArray = response.data.projects.map(function (project) {
          return {
            value: project.id,
            label: project.title,
          };
        });
        setProjects(transformedArray);
      } catch (error) {
        console.error("Ошибка при получении списка проектов:", error);
      }
    }
  }

  useEffect(() => {
    getProjects();
  }, []);

  const handleAddTicket = () => {
    const newTicketData = {
      title: name,
      project_id: project,
      urgency_id: urgency,
      department_id: department,
      target_date: date,
      description: descr,
    };

    const headers = {
      "Content-Type": "application/json",
      Authorization: window.localStorage.getItem("token"),
    };

    const url =
      "https://soultri.site/sochi_park_gitlab/api/tickets/create_ticket.php";

    axios
      .post(url, newTicketData, { headers })
      .then((response) => {
        console.log("Ответ от сервера:", response.data);
        nav(`/tickets`);
      })
      .catch((error) => {
        console.error("Произошла ошибка:", error);
      });
  };

  return (
    <>
      <h1 className="title newTickets__title">Новая заявка</h1>
      <div className="newTickets__box">
        <div className="newTickets__box--content">
          <h3 className="newTickets__box-title">Заполните данные</h3>
          <form
            className="newTickets__box-form"
            onSubmit={(e) => e.preventDefault()}
          >
            <div className="newTickets__form-item">
              <label className="newTickets__form-label">Название</label>
              <Input
                className="newTickets__form-input"
                placeholder=""
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="df">
              <div className="newTickets__form-item">
                <label className="newTickets__form-label">Проект</label>
                <Select
                  className="newTickets__form-select"
                  showSearch
                  placeholder="Не выбрано"
                  optionFilterProp="children"
                  onChange={handleProjectSelect}
                  options={projects}
                />
              </div>
              <div className="newTickets__form-item">
                <label className="newTickets__form-label">Срочность</label>
                <Select
                  className="newTickets__form-select"
                  showSearch
                  placeholder="Не выбрано"
                  optionFilterProp="children"
                  onChange={handleUrgencySelect}
                  options={[
                    {
                      value: 1,
                      label: "Низкая срочность",
                    },
                    {
                      value: 2,
                      label: "Средняя срочность",
                    },
                    {
                      value: 3,
                      label: "Высокая срочность",
                    },
                  ]}
                />
              </div>
            </div>
            <div className="newTickets__form-item--last df">
              <div className="newTickets__form-item">
                <label className="newTickets__form-label">
                  Какому отделу присвоить задачу?
                </label>
                <Input
                  className="newTickets__form-input"
                  placeholder="ID"
                  onChange={(e) => setDepartment(e.target.value)}
                />
                {/* <Select
                  className="newTickets__form-select"
                  showSearch
                  placeholder="Не выбрано"
                  optionFilterProp="children"
                  onChange={handleDepartmentSelect}
                  options={[
                    {
                      value: "low",
                      label: "Низкая срочность",
                    },
                  ]}
                /> */}
              </div>
              <div className="newTickets__form-item">
                <label className="newTickets__form-label">
                  Когда выполнить задачу?
                </label>
                <DatePicker
                  style={{ width: "100%" }}
                  onChange={handleDatePick}
                />
              </div>
            </div>
            <div className="newTickets__form-item newTickets__form-item--textarea">
              <label className="newTickets__form-label">Описание</label>
              <textarea
                rows={6}
                className="newTickets__form-textarea"
                onChange={(e) => setDescr(e.target.value)}
              ></textarea>
            </div>
            <div className="newTickets__form-footer">
              <button
                className="button--purple newTickets__form-footer--left"
                onClick={handleAddTicket}
              >
                Создать заявку
              </button>
              <button
                className="newTickets__form-footer--right"
                onClick={() => window.history.back()}
              >
                Отмена
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default TicketsNew;
