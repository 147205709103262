function formatTimeAgo(timestamp) {
  // Преобразовать строку времени в объект Date
  const date = new Date(timestamp);
  // Текущее время
  const now = new Date();

  // Разница в миллисекундах между текущим временем и заданным временем
  const diff = now - date;

  // Количество миллисекунд в годе, дне, часе и минуте
  const msPerYear = 1000 * 60 * 60 * 24 * 365;
  const msPerDay = 1000 * 60 * 60 * 24;
  const msPerHour = 1000 * 60 * 60;
  const msPerMinute = 1000 * 60;

  // Вычислить количество лет, дней, часов и минут в разнице
  const years = Math.floor(diff / msPerYear);
  const days = Math.floor((diff % msPerYear) / msPerDay);
  const hours = Math.floor((diff % msPerDay) / msPerHour);
  const minutes = Math.floor((diff % msPerHour) / msPerMinute);

  // Форматировать строку вывода
  let result = "";
  if (years > 0) {
    result += `${years} год `;
  }
  if (days > 0) {
    result += `${days} дней `;
  }
  if (hours > 0) {
    result += `${hours} часов `;
  }
  if (minutes > 0) {
    result += `${minutes} мин `;
  }
  result += "назад";

  return result.trim();
}



export default formatTimeAgo;