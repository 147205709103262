import React from 'react'
import { Link } from 'react-router-dom';
import TicketsTable from './components/TicketsTable';
import './Tickets.scss';

const Requests = () => {
  

  return (
    <>

      <TicketsTable />
    </>
  )
}

export default Requests