import React from 'react'

const Sidebar3 = ({stroke}) => {
  return (
    <svg width="24.000000" height="24.000000" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
	<desc>
			Created with Pixso.
	</desc>
	<defs/>
	<path id="Vector" d="M12 21.25C6.89 21.25 2.75 17.1 2.75 12C2.75 6.89 6.89 2.75 12 2.75C17.1 2.75 21.25 6.89 21.25 12C21.25 17.1 17.1 21.25 12 21.25Z" stroke={stroke} stroke-opacity="1.000000" stroke-width="1.500000" stroke-linejoin="round"/>
	<path id="Vector 1" d="M2.5 12L11.75 12L21 12" stroke={stroke} stroke-opacity="1.000000" stroke-width="1.500000"/>
	<path id="Vector" d="M12 21C11.38 21 10.82 20.45 10.34 19.49C9.53 17.89 9 15.13 9 12C9 9.5 9.33 7.25 9.87 5.64C10.01 5.2 10.17 4.82 10.34 4.5C10.82 3.54 11.38 3 12 3" stroke={stroke} stroke-opacity="1.000000" stroke-width="1.500000" stroke-linejoin="round" stroke-linecap="round"/>
	<path id="Vector" d="M12 3C12.61 3 13.17 3.54 13.65 4.5C14.46 6.1 15 8.86 15 12C15 14.49 14.66 16.74 14.12 18.35C13.98 18.79 13.82 19.17 13.65 19.49C13.17 20.45 12.61 21 12 21" stroke={stroke} stroke-opacity="1.000000" stroke-width="1.500000" stroke-linejoin="round" stroke-linecap="round"/>
</svg>

  )
}

export default Sidebar3