import React, { useEffect, useState } from "react";
import { Modal, Input, Select } from "antd";
import closeIcon from "../../../assets/icons/close-icon.svg";
import axios from "axios";
import AddEmployeeModal from "../../employees/components/AddEmployeeModal";

const AddProjectModal = ({ open, onCancel }) => {
  const [id, setId] = useState("");
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [empOptions, setEmpOptions] = useState([]);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [token, setToken] = useState(null);

  const showAddModal = () => {
    setIsAddModalOpen(true);
  };
  const handleAddModalCancel = () => {
    setIsAddModalOpen(false);
  };
  useEffect(() => {
    setToken(window.localStorage.getItem('token'));
  }, [])

  const handleAddProject = () => {
    const requestData = {
      gitlab_id: id,
      employees_id: selectedEmployees,
    };

    const headers = {
      "Content-Type": "application/json",
      Authorization: window.localStorage.getItem("token"),
    };

    axios
      .post(
        "https://soultri.site/sochi_park_gitlab/api/projects/create_project.php",
        requestData,
        { headers }
      )
      .then((response) => {
        console.log("Ответ от сервера:", response.data);
        onCancel();
      })
      .catch((error) => {
        console.error("Произошла ошибка:", error);
      });
  };

  const handleEmployeeChange = (value) => {
    console.log(value)
    const emp = value.map(item => parseInt(item))
    console.log(emp)
    setSelectedEmployees(emp);
  };

  const getEmployees = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: window.localStorage.getItem("token"),
    };

    const url = `https://soultri.site/sochi_park_gitlab/api/employees/get_employees.php`;

    axios
      .post(url, {}, { headers })
      .then((response) => {
        console.log("Информация о сотрудниках:", response.data.employees);
        let transformedArray = response.data.employees.map(function (employee) {
          return {
            value: employee.id,
            label: employee.name,
          };
        });
        setEmpOptions(transformedArray);
        setEmployees(response.data.employees);
      })
      .catch((error) => {
        console.error(
          "Произошла ошибка при получении информации о сотрудниках:",
          error
        );
      });
  };

  useEffect(() => {
    getEmployees();
  }, []);

  const handleRemoveSelectedEmployee = (idToRemove) => {
    setSelectedEmployees(selectedEmployees.filter(function(id) {
      return id !== idToRemove;
    }))
  }

  return (
    <Modal
      open={open}
      title=""
      onCancel={onCancel}
      footer={[]}
      width={420}
      className="addProj__modal"
    >
      <h5 className="addProj__title">Добавить новый проект</h5>
      <Input
        className="addProj__input"
        placeholder="GitLab ID"
        onChange={(e) => setId(e.target.value)}
      />
      <div className="df jcsb aic">
        <p className="addProj__text">Доступ сотрудников: </p>
        <button className="addProj__button" onClick={showAddModal}>
          Добавить
        </button>
        <Modal
          open={isAddModalOpen}
          title=""
          onCancel={handleAddModalCancel}
          footer={[]}
          width={420}
          className="addProj__modal"
        >
          <h4 className="addEmp__title">Выбрать сотрудников</h4>
          <Select
            mode="multiple"
            allowClear
            style={{ width: "100%" }}
            placeholder="Не выбрано"
            onChange={handleEmployeeChange}
            options={empOptions}
          />
          <div className="df jcc">
            <button
              className="button--purple addEmp__button"
              onClick={handleAddModalCancel}
            >
              Выбрать сотрудников
            </button>
          </div>
        </Modal>
      </div>
      <div className="addProj__employees">
        {selectedEmployees.map((id) => (
          <div className="addProj__employee" key={id}>
            <img className="addProj__employee-close" src={closeIcon} onClick={() => handleRemoveSelectedEmployee(id)} />
            <h5 className="addProj__employee-name">
              {employees.find(function (employee) {
                return employee.id == id;
              }).name}
            </h5>
            <p className="addProj__employee-job">{employees.find(function (employee) {
                return employee.id == id;
              }).role}</p>
          </div>
        ))}
      </div>
      <div className="addProj__footer">
        <button
          className="addProj__footer-button addProj__footer-button--purple"
          onClick={handleAddProject}
        >
          Сохранить
        </button>
        <button
          className="addProj__footer-button addProj__footer-button--white"
          onClick={onCancel}
        >
          Удалить
        </button>
      </div>
    </Modal>
  );
};

export default AddProjectModal;
