function formatSeconds(seconds) {
  // Перевод секунд в дни, часы, минуты и остаток секунд
  const days = Math.floor(seconds / 86400);
  const remainingSeconds = seconds % 86400;

  // Вычисление часов, минут и секунд из остатка
  const hours = Math.floor(remainingSeconds / 3600);
  const remainingMinutes = Math.floor((remainingSeconds % 3600) / 60);
  const remainingSecondsFinal = remainingSeconds % 60;

  // Формирование строки с днями, часами, минутами и секундами
  let result = '';
  if (days > 0) {
    result += days + ' д ';
  }
  if (hours > 0) {
    result += hours + ' ч ';
  }
  if (remainingMinutes > 0 || remainingSecondsFinal > 0 || result === '') {
    // Добавляем минуты и секунды только если они существуют или строка еще пустая
    result += remainingMinutes + ',' + (remainingSecondsFinal < 10 ? '0' : '') + remainingSecondsFinal + ' мин';
  }
  
  return result;
}

export default formatSeconds;
